import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../_services/auth.service';
import { CommonDataService } from '../../../_services/common-data.service';
import { first } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';

@Component({
  selector: 'app-login-user',
  templateUrl: './login-user.component.html',
  styleUrls: ['./login-user.component.scss']
})
export class LoginUserComponent implements OnInit {

  modelForm: FormGroup;
  buttonText = 'Login'
  modelId = '0'
  modelData 


  constructor(
    private authService: AuthService,  
    private router: Router, 
    private route: ActivatedRoute,
    private httpClient: HttpClient,  
    private formBuilder: FormBuilder,
    private _data: CommonDataService,
  ) {
  }

  ngOnInit(): void {

    this.modelForm = this.formBuilder.group({

      email: ['', [Validators.required, Validators.email]], 
      password: ['', Validators.required], 
      terms: [0, Validators.required]

    });

    this.authService.deleteSessionKey('PersonalDetails');
    this.authService.logout()

    this.authService.loggedInChanged.next({});

  }

  login() {

    if(!this.modelForm.controls['terms'].value) {
      alert("Please accept our terms and conditions. Thanks")
      return
    }

    let modelObject1 = new FormData();
    modelObject1.append('auth_username', this.modelForm.controls['email'].value);
    modelObject1.append('auth_password', this.modelForm.controls['password'].value);
    this.buttonText = "Please wait ....";


    this._data.dynamicFormPost(modelObject1, 'api-login') 
      .pipe(first())
      .subscribe(
        data => {

          // this.authService.setAutoLoggin(true, this.auth_username, this.auth_password);
          let done = this.authService.getUserProfileDetails();
          this.authService.loggedInChanged.next({});

          // if(this.authService.getSessionDetails('DeliveryRecipientDetails')) {
          //   this.router.navigate(['/pay-now'])
          //   return true
          // }

          this.router.navigate(['location-search']);


          // this.modelData = data['cover']['model']['modelData']['formFieldsEntities'];

          // if(data['cover']['model']['modelData']['formFieldsEntities']['is_seller']) {
          //   //localStorage.setItem('BNFGSHGFGG', 'TYHH50545HSJSJ21FFSDGSFFFSBHSFVSTVS410045');
          //   sessionStorage['BNFGSHGFGG'] = 'TYHH50545HSJSJ21FFSDGSFFFSBHSFVSTVS410045';
          // }
        },
        error => {
          console.log(error);
          alert('Whoops something went wrong. Please try again!')
          this.buttonText = "Sign In";

        },

        () => {


        }
      );
  }

  guestUser() {

    this._data.dynamicFormPost({}, 'guest-user-auth').subscribe(
      data => {
        // if(this.authService.getSessionDetails('DeliveryRecipientDetails')) {
        //   this.router.navigate(['/pay-now'])
        //   return true
        // }
    
        this.router.navigate(['location-search']);
      },
      err => {
        console.log('error')
      },
      () => {}
    );

  }

}
