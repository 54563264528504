import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormsModule, FormArray  } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';



import { CommonDataService } from '../../../_services/common-data.service';
import { AuthService } from '../../../_services/auth.service';


@Component({
  selector: 'app-complete-paynow',
  templateUrl: './complete-paynow.component.html',
  styleUrls: ['./complete-paynow.component.scss']
})
export class CompletePaynowComponent implements OnInit {

  loading = true
  showWebView = false

  timeLeft = 500000;
  // timeLeft = 60000;
  interval;
  tracktimeout;

  quoteDetails;
  orderDetails;
  webView;
  iframe
  trustedHtml

  //transaction check details
  paymentTransactionRef
  checkComplete = false
  transactionRef

  constructor( 
    private nav: Router, 
    private authService: AuthService, 
    private _data: CommonDataService,
    private sanitizer: DomSanitizer

    ) { 


    }

  webViewSrc;

  ngOnInit(): void {

    if(this.authService.getSessionDetails("CourierQuoteDetails")) {
      this.quoteDetails = this.authService.getSessionDetails("CourierQuoteDetails");
      this.orderDetails = this.authService.getSessionDetails("OrderDetails");

      this.paymentTransactionRef = this.quoteDetails['waybillReference'];

      this.getPaymentDetails()
    } else {
      this.nav.navigate(['/location-search']);

    }

  }

  fetchPaymentDetails() {

  }

  getPaymentDetails() {

    let modelObject1 = {
      "id" : this.orderDetails['id'],
      "amount" :  this.orderDetails['totalInclusive']
    }

    this._data.dynamicFormPost(modelObject1, "get-payment-details-paystack") 
        .pipe(first())
        .subscribe(
          data => {

            if(data['paystack']['Payment']['authorization_url'] != undefined) {
              
              this.webViewSrc = data['paystack']['Payment']['authorization_url'];
              this.transactionRef = data['paystack']['Payment']['reference'];

              this.iframe = '<iframe id="pf" src="'+this.webViewSrc+'" name="pf" scrolling="no" frameborder="0" style="position: relative;height: 50rem;width: 20rem;"></iframe>';

              this.trustedHtml = this.sanitizer.bypassSecurityTrustHtml(this.iframe);

              this.showWebView = true;
              this.loading = false
              this.trackPaystackPaymentStatus()
              this.startTimer()

            }
  
          },
          error => {

            alert("Whoops, something went wrong. Please try again")
            this.nav.navigate(['/pay-now']);

          },
  
          () => {

          }
        );
  }

  trackPaystackPaymentStatus() {

    let modelObject1 = {
      "transactionReference" : this.transactionRef,
      "amount" :  this.orderDetails['totalInclusive']
    }

    this._data.dynamicFormPost(modelObject1, "api-order-complete-check-paystack") 
        .pipe(first())
        .subscribe(
          data => {

            if(data['paystackResponse']['status'] != undefined) {
              
              if(data['paystackResponse']['status'] == "success") {
                this.checkComplete = true

                let transactionDetails = {
                  id: data['transaction']['id'],
                  quoteId : this.quoteDetails['waybillQuoteId']
                }

                this.authService.saveSessionDetails(transactionDetails, "newTransactionDetails")
                // this.nav.navigate(['/home/payment-success']);
                clearInterval(this.interval);
                clearInterval(this.interval);
                clearTimeout(this.tracktimeout);
                this.completeOrder(this.transactionRef)
                
              }
    
              if( data['paystackResponse']['status'] == "failed" ) {
                this.checkComplete = true
                this.nav.navigate(['/home/payment-failed']);
              }

              if( data['pfObject']['paystack']['Payment']['status'] == "cancelled" ) {
                this.checkComplete = true
                this.nav.navigate(['/home/payment-failed']);
              }
    
              else {

                if(this.timeLeft > 0) {
                  // console.log('status not yet ready')
                  //     this.tracktimeout = setTimeout(()=>{    //<<<---    using ()=> syntax
                  //       this.trackPaystackPaymentStatus();
                  // }, 6000);
                }

                else {
                  this.paymentFailed()
                }
                
              }

            }
  
          },
          error => {

          },
  
          () => {

          }
        );
  }

startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft-= 1000;
        console.log(this.timeLeft + "is the timeout remaining seconds")
        this.trackPaystackPaymentStatus();
      } 
    },6000)
  }

  paymentFailed() {

    clearInterval(this.interval);
    clearTimeout(this.tracktimeout);

    this.showWebView = false;
    this.trackPaystackPaymentStatus()

    setTimeout(()=>{    //<<<---    using ()=> syntax

      //wait one last time and make sure the payment wont return anything
      if(this.checkComplete != true) {

        this.nav.navigate(['/home/payment-failed']);

      }
      
    }, 15000);

    
  }

  completeOrder(txn) {

    // let modelObject1 = new FormData();

    // modelObject1.append('m_payment_id', txn);
    // modelObject1.append('waybillQuoteRef', this.quoteDetails['waybillReference']);

    let modelObject1 = {
      "m_payment_id" : txn,
      "waybillQuoteRef" :  this.quoteDetails['waybillReference']
    }

    this._data.dynamicFormPost(modelObject1, "complete-gaslit-order-paylater") 
        .pipe(first())
        .subscribe(
          data => {

             //clean out cart 
             this.authService.deleteSessionKey('DeliveryRecipientDetails')
             this.authService.deleteSessionKey('DeliveryDropOffAddress')
             this.authService.deleteSessionKey('DeliveryLoadSize')
             this.authService.deleteSessionKey('OrderDetails')
             this.authService.deleteSessionKey('order')
             this.authService.deleteSessionKey('CourierQuoteDetails')
             this.authService.deleteSessionKey('totalCartCost')
             this.authService.deleteSessionKey('newTransactionDetails')

             this.authService.saveSessionDetails(data['transaction']['refNumber'], "refNumber")
             this.nav.navigate(['/checkout/payment-success']);
  
          },
          error => {

            //Post paymemt things, like emails and stuff. If it fails should dealt with internally
            this.authService.deleteSessionKey('DeliveryRecipientDetails')
            this.authService.deleteSessionKey('DeliveryDropOffAddress')
            this.authService.deleteSessionKey('OrderDetails')
            this.authService.deleteSessionKey('order')
            this.authService.deleteSessionKey('CourierQuoteDetails')
            this.authService.deleteSessionKey('totalCartCost')
            this.authService.deleteSessionKey('newTransactionDetails')


            this.authService.saveSessionDetails(this.transactionRef, "refNumber")
            this.nav.navigate(['/checkout/payment-error']);

            // dialogs.action({
            //   message: "Payment was successful but there was an issue creating your order. Please follow up with REF:"+ txn ,
            //   cancelButtonText: "Close",
            //   actions: ["1. Cancel"]
  
            // }).then(result => {
            //     console.log("Dialog result: " + result);
            //     if(result != "Close") {
  
            //       switch (result) {
  
            //         case "1. Cancel":
            //             this.nav.navigate(["/home"]);
            //           break;
                  
            //         default:
            //             this.nav.navigate(["/home"]);
            //           break;
            //       }
            //     }
  
            //     else {
            //       this.nav.navigate(["/home"]);
            //     }
            // });

          },
  
          () => {

          }
        );
  }

}
