import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";

@Component({
  selector: 'app-footer-nav',
  templateUrl: './footer-nav.component.html',
  styleUrls: ['./footer-nav.component.scss']
})
export class FooterNavComponent implements OnInit {

  constructor() { }

  @HostListener("document:mousemove", ["$event"])

  ngOnInit(): void {
  }

}
