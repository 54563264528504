import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

import { IndexComponent } from "./pages/index/index.component";
import { ProfilepageComponent } from "./pages/examples/profilepage/profilepage.component";
import { RegisterpageComponent } from "./pages/examples/registerpage/registerpage.component";
import { LandingpageComponent } from "./pages/examples/landingpage/landingpage.component";
import { SignUpComponent } from './pages/examples/sign-up/sign-up.component';
import { SellerBusinessDetailsComponent } from './pages/examples/seller-business-details/seller-business-details.component';
import { AddGassComponent } from './pages/checkout/add-gass/add-gass.component';
import { ServiceLocationSearchComponent } from './pages/checkout/service-location-search/service-location-search.component';
import { LoginUserComponent } from './pages/checkout/login-user/login-user.component';
import { PayNowComponent } from './pages/checkout/pay-now/pay-now.component';
import { CompletePaynowComponent } from './pages/checkout/complete-paynow/complete-paynow.component';
import { PaymentSuccessComponent } from './pages/checkout/payment-success/payment-success.component';
import { PaymentErrorComponent } from './pages/checkout/payment-error/payment-error.component';
import { ProfileDashboardComponent } from './pages/profile/profile-dashboard/profile-dashboard.component';

import { AuthGuard } from './_services/auth.guard';

const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "home", component: IndexComponent },
  { path: "contact", component: ProfilepageComponent },
  { path: "sign-up", component: SignUpComponent },
  { path: "sessions/login", component: LoginUserComponent },
  { path: "register", component: RegisterpageComponent },
  { path: "sellers/register/business-details/:id", component: SellerBusinessDetailsComponent },
  { path: "landing", component: IndexComponent },

  //Checkout steps 
  { path: "location-search", component: ServiceLocationSearchComponent},
  // { path: "order-now", component: AddGassComponent},
  // { path: "pay-now", component: PayNowComponent },

  // { path: "location-search", component: ServiceLocationSearchComponent , canActivate: [AuthGuard]},
  { path: "order-now", component: AddGassComponent, canActivate: [AuthGuard] },
  { path: "pay-now", component: PayNowComponent, canActivate: [AuthGuard] },
  { path: "complete-payment", component: CompletePaynowComponent, canActivate: [AuthGuard] },
  { path: "profile/dashboard", component: ProfileDashboardComponent, canActivate: [AuthGuard] },


  //Payment Response pages
  { path: "checkout/payment-success", component: PaymentSuccessComponent , canActivate: [AuthGuard]},
  { path: "checkout/payment-error", component: PaymentErrorComponent, canActivate: [AuthGuard] },


];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled'
    })
  ],

  providers: [
    AuthGuard,

  ],
  exports: []
})
export class AppRoutingModule {}
