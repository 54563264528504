// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://hearkern.api.sbsoftware.co.za/Alpha/bot-api/public/alpha',
  // apiUrl: 'http://localhost:8272/bot-api/public/alpha', //Ubuntu 3
  // apiUrl: 'http://3.123.117.58/gaslit-app/Alpha/bot-api/public/alpha'

  twitter: 'https://twitter.com/gaslit18',
  facebook: 'https://www.facebook.com/profile.php?id=61564085305683',
  instagram: 'https://www.instagram.com/gaslitza/'


};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
