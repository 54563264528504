import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HttpResponse
} from '@angular/common/http';

import {catchError, tap} from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()

export class AuthInterceptor implements HttpInterceptor {

  constructor( private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    let apikey = localStorage.getItem('apikey');
    let authkey = localStorage.getItem('authkey');

        if (apikey && authkey) {
            request = request.clone({
                setHeaders: {
                    // 'Authorization': 'Bearer '+currentUser.securityToken,
                    //'Content-Type':  'application/json',
                    //'Accept': 'application/json',
                    'apikey': apikey,
                    'authkey': authkey
                }
            });
        }



    return next.handle(request).pipe(
            tap(event => {
                if (event instanceof HttpResponse)
                {
                    // catch the rolling api key...
                    if (event.body.apikey && event.body.authkey)
                    {
                        localStorage.setItem('apikey', event.body.apikey);
                        localStorage.setItem('authkey', event.body.authkey);
                    }
                }
            }),

            catchError((error, caught) => {
                //intercept the respons error and displace it to the console
                //console.log(error);
                this.handleAuthError(error);
                //return of(error);
                if (error.error['apikey'] && error.error['authkey'])
                {
                    localStorage.setItem('apikey', error.error.apikey);
                    localStorage.setItem('authkey', error.error.authkey);
                }
                throw error.error;
    }) as any );
    
  }

  private handleAuthError(err: HttpErrorResponse): void {

      if (err.status === 401) {
        //navigate /delete cookies or whatever
        console.log('handled error ' + err.status);

        localStorage.removeItem('authkey');
        localStorage.removeItem('apikey');
        this.router.navigate(['/home']);

        //return true;

        //return of(err.message);
      }
        
      //throw error.error;
  } 
  
}