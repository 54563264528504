import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormsModule, FormArray  } from '@angular/forms';
import { first } from 'rxjs/operators';

import { CommonDataService } from '../../../_services/common-data.service';
import { AuthService } from '../../../_services/auth.service';

@Component({
  selector: 'app-add-gass',
  templateUrl: './add-gass.component.html',
  styleUrls: ['./add-gass.component.scss']
})
export class AddGassComponent implements OnInit {

  gasBottles = []
  modelForm: FormGroup;
  paynowbtn = "Complete Purchase "
  showModal = true

  orderItems = []
  productSelection = []
  order;
  orderId = '0'


  totalCost = 0.00
  totalCylinders = 0;

  tankSizes = []
  tankSize
  tanksizeOptions = []
  pageReady = false;
  deliveryAddress;
  username = "Guest User"
  personalDetails

  // orderData
  // supplierId
  totalCartCost
  totalInclusive
  serviceCharge

  supplierId
  waybillId
  waybillReference
  waybillQuoteAmount
  recipientDetails;


  constructor(
    private formBuilder: FormBuilder,
    private nav: Router,
    private _data: CommonDataService,
    private authService:  AuthService
  ) { }

  ngOnInit(): void {


    if(this.authService.getSessionDetails('PersonalDetails')) {
      this.personalDetails = this.authService.getSessionDetails('PersonalDetails');

      this.username = this.personalDetails['first_name']

      this.modelForm = this.formBuilder.group({

        email: [this.personalDetails['email'], Validators.required], 
        first_name: [this.username, Validators.required], 
        last_name: [this.personalDetails['last_name'], Validators.required], 
        phone: [this.personalDetails['phone'], Validators.required]
  
      });

    } else {

      this.modelForm = this.formBuilder.group({

        email: ['', Validators.required], 
        first_name: ['', Validators.required], 
        last_name: ['', Validators.required], 
        phone: ['', Validators.required]
  
      });

      // this.nav.navigate(['/sessions/login'])
      // alert("Please login to proceed")
      // return;
    }

    // this.modelForm = this.formBuilder.group({

    //   email: [this.personalDetails['email'], Validators.required], 
    //   first_name: [this.username, Validators.required], 
    //   last_name: [this.personalDetails['last_name'], Validators.required], 
    //   phone: [this.personalDetails['phone'], Validators.required]

    // });


    if(this.authService.getSessionDetails("DeliveryDropOffAddress") ) {
      this.deliveryAddress = this.authService.getSessionDetails("DeliveryDropOffAddress");
      this.getGaslitTanks()
    } else {
      alert("Please select a valid delivery address")
      this.cancelOrder()
      this.nav.navigate(['location-search'])
    }
  }

  getGaslitTanks() {
    var formData = new FormData();
    formData.append('modelName', 'GasCylinderTanks')

    this._data.getModelData(formData).subscribe(
      data => {
      this.gasBottles = data['covers']
      //console.log('addresses are is: '+JSON.stringify(this.addresses))
      // this.scrollToBottom();
      if(data['covers'].length > 0) {
  
        for (let index = 0; index < data['covers'].length; index++) {
          const element = data['covers'][index];  

          let optionObject = {
            "quantity": 0,
            "id" : element['id'],
            "price" : 0.0,
            // "price" : parseFloat(element['cover']['model']['modelData']['formFieldsEntities']['tankDetails']['price']),
            "name": element['cover']['model']['modelData']['formFieldsEntities']['tankDetails']['name'],
            "bottleCharge": parseFloat(element['cover']['model']['modelData']['formFieldsEntities']['tankDetails']['bottleCharge']),
            "subCategoryId": element['cover']['model']['modelData']['formFieldsEntities']['tankDetails']['subCategoryId'],
            "dimensions" : "",
            "keyName" : element['cover']['model']['modelData']['formFieldsEntities']['tankDetails']['keyName'],
            "description" : "",

          }

          this.tankSizes.push(optionObject)
          this.tanksizeOptions.push(optionObject['name'])
        }

        this.locateAreaSupplier()

      }
      console.log(this.gasBottles)
      },
      err => {
        console.log('error')
      },
      () => {}
    );
  }

  locateAreaSupplier() {

    let waybillRequestQuoteObject = {

      "toAddress": {
          "lat": this.deliveryAddress.lat,
          "lng": this.deliveryAddress.lng,
          "fullAddress": this.deliveryAddress.fullAddress,
          "post_code": this.deliveryAddress.delivery_post_code,
          "city": this.deliveryAddress.delivery_city,
          "baundary" : this.deliveryAddress.delivery_city,
          // "phone": this.recipientDetails.contact_number,
          // "email": this.recipientDetails.contact_email,
          // "recipient" : this.recipientDetails.recipient_name
      },

      "orderItems": this.tankSizes,
      "totalCartCost" : this.totalCartCost,
      "getCylindersOnly" : true

    }

    this._data.dynamicFormPost(waybillRequestQuoteObject, 'locate-supplier-area-coverage') 
      .pipe(first())
      .subscribe(
        data => {

          if(data['orderItems'] != undefined && data['quote']['areaCovered'] == true) {

            this.pageReady = true;
            this.tankSizes = data['orderItems']

          }

          else {

            alert("Sorry, service not in your area as yet. We are working hard as we can to get your area on to the gaslit service.  ")
            this.cancelOrder()
            this.nav.navigate(['/location-search'])


          }

          this.pageReady = true;
        },
        error => {

          alert("Whoops something went wrong...Please try again. ")
          this.cancelOrder()
          this.nav.navigate(['/location-search'])


        },
        () => {}
      );
  }


  cancelOrder() {
  
    this.authService.deleteSessionKey('DeliveryRecipientDetails')
    this.authService.deleteSessionKey('DeliveryDropOffAddress')
    this.authService.deleteSessionKey('DeliveryLoadSize')
    // this.authService.deleteSessionKey('CourierQuoteDetails')
  }

    
  onNavigate(path: String): void {
  
    this.nav.navigate([path]);
  }

  updateDetails() {  
    if(this.validCart) {
      //lets save up the recipient details 
      this.authService.saveSessionDetails(this.totalCost, "totalCartCost")
      this.authService.saveSessionDetails(this.orderItems, "DeliveryLoadSize")
      this.nav.navigate(['/home/new-delivery-recipient-details'])
    }

    return false;
  }

  validCart() {
    let cartSize = this.orderItems.length

      if(cartSize > 0) {
        return true
      }

      return false
  }

  cartTotals() {

    this.totalCost = 0;
    this.totalCylinders = 0;

    this.orderItems.forEach(element => {
      
      this.totalCost += (element.price + element.bottleCharge) * element.quantity;
      this.totalCylinders += element.quantity;

    }); 

  }

  plusItem(keyname) {
      this.orderItems.forEach( (item, index) => {
        if(item.keyName == keyname) {
          this.orderItems[index]['quantity'] += 1;
        } 
      });

      this.cartTotals()
  }

  selectTankSize(name) {

    let skip = false
    this.orderItems.forEach( (item, index) => {
      if(item.name == name) {
        this.orderItems[index]['quantity'] += 1;
        this.cartTotals()

        skip = true
      } 
    });

    if(!skip) {
      this.tankSizes.forEach(element => {
        if(element['name'] == name) {
          this.tankSize = element
          element.quantity = 1
  
          this.orderItems.push(element)
          this.cartTotals()

          
        }
      });
    }
    
  }

  minusItem(keyname) {
    console.log( this.orderItems)
      this.orderItems.forEach( (item, index) => {
        if(item.keyName == keyname) {
          this.orderItems[index]['quantity'] -= 1;

          if(this.orderItems[index]['quantity'] < 1) {
            this.orderItems.splice(index,1);
          }
        }
      });

      this.cartTotals()
  }

  completePurchaseOrder() {
    if (this.modelForm.invalid || !this.validCart() ) {
      console.log('errors are detected');

      alert("Please fill in all the relevant information to Proceed!")

      return;
    }
  
    let recipientDetails = {
      recipient_name : this.modelForm.controls['first_name'].value,
      recipient_last_name : this.modelForm.controls['last_name'].value,
      contact_number: this.modelForm.controls['phone'].value,
      contact_email: this.modelForm.controls['email'].value
    }

    this.authService.saveSessionDetails(this.totalCost, "totalCartCost")
    this.authService.saveSessionDetails(this.orderItems, "DeliveryLoadSize")
    this.authService.saveSessionDetails(recipientDetails, "DeliveryRecipientDetails")

    if(!this.authService.getSessionDetails('PersonalDetails')) {
        // this.nav.navigate(['/sessions/login'])
        this.createModel(recipientDetails) 
        return true
    }

    this.nav.navigate(['/pay-now'])
  }

  createModel(recipientDetails) {

    let modelObject1 = new FormData();

    modelObject1.append('modelName', "User");
    modelObject1.append('PersonalDetails-email', recipientDetails.contact_email);
    modelObject1.append('PersonalDetails-first_name', recipientDetails.recipient_name);
    modelObject1.append('PersonalDetails-last_name', recipientDetails.recipient_last_name);
    modelObject1.append('PersonalDetails-phone', recipientDetails.contact_number);  

    this.updateModel(modelObject1);

  }

  updateModel (modelObject: any) {
    // Initiate new model creation/update method

    this._data.updateProfile(modelObject) 
      .pipe(first())
      .subscribe(
        data => {

          this.nav.navigate(['/pay-now'])

        },
        error => {
          this.nav.navigate(['/pay-now'])

        },

        () => {

        }
      );
  }


}
