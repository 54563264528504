import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule ,    ReactiveFormsModule,} from "@angular/forms";
import { RouterModule } from "@angular/router";

import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { TabsModule } from "ngx-bootstrap/tabs";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { AlertModule } from "ngx-bootstrap/alert";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { ModalModule } from "ngx-bootstrap/modal";
import { JwBootstrapSwitchNg2Module } from "jw-bootstrap-switch-ng2";
import { PopoverModule } from "ngx-bootstrap/popover";

import { IndexComponent } from "./index/index.component";
import { ProfilepageComponent } from "./examples/profilepage/profilepage.component";
import { RegisterpageComponent } from "./examples/registerpage/registerpage.component";
import { LandingpageComponent } from "./examples/landingpage/landingpage.component";
import { SignUpComponent } from './examples/sign-up/sign-up.component';
import { SellerBusinessDetailsComponent } from './examples/seller-business-details/seller-business-details.component';
import { AddGassComponent } from './checkout/add-gass/add-gass.component';
import { ServiceLocationSearchComponent } from './checkout/service-location-search/service-location-search.component';
import { LoginUserComponent } from './checkout/login-user/login-user.component';
import { PayNowComponent } from './checkout/pay-now/pay-now.component';
import { CompletePaynowComponent } from './checkout/complete-paynow/complete-paynow.component';
import { PaystackRedirectComponent } from './checkout/paystack-redirect/paystack-redirect.component';
import { PaymentSuccessComponent } from './checkout/payment-success/payment-success.component';
import { PaymentErrorComponent } from './checkout/payment-error/payment-error.component';
import { ProfileDashboardComponent } from './profile/profile-dashboard/profile-dashboard.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    ProgressbarModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    CollapseModule.forRoot(),
    JwBootstrapSwitchNg2Module,
    TabsModule.forRoot(),
    PaginationModule.forRoot(),
    AlertModule.forRoot(),
    BsDatepickerModule.forRoot(),
    CarouselModule.forRoot(),
    ModalModule.forRoot()
  ],
  declarations: [
    IndexComponent,
    ProfilepageComponent,
    RegisterpageComponent,
    LandingpageComponent,
    SignUpComponent,
    SellerBusinessDetailsComponent,
    AddGassComponent,
    ServiceLocationSearchComponent,
    LoginUserComponent,
    PayNowComponent,
    CompletePaynowComponent,
    PaystackRedirectComponent,
    PaymentSuccessComponent,
    PaymentErrorComponent,
    ProfileDashboardComponent
  ],
  exports: [
    IndexComponent,
    ProfilepageComponent,
    RegisterpageComponent,
    LandingpageComponent
  ],
  providers: []
})
export class PagesModule {}
