import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormsModule, FormArray  } from '@angular/forms';
import { first } from 'rxjs/operators';

//import { UserService } from '../../services/user.service';
import { CommonDataService } from '../../../_services/common-data.service';
import { AuthService } from '../../../_services/auth.service';


@Component({
  selector: 'app-service-location-search',
  templateUrl: './service-location-search.component.html',
  styleUrls: ['./service-location-search.component.scss']
})
export class ServiceLocationSearchComponent implements OnInit {

  modelForm: FormGroup;
  //mapbox search address stuff
  //mapbox search stuff
  searchForm: FormGroup;
  selectedAddress = [];
  addresses = [];
  selectAddress = false;

  query: any;
  showResults = false;
  loading = false;
  doneSearch = false;
  username = "";

  deliveryFullAddress = ''

  addressObjects = []
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private _data: CommonDataService,
    private authService:  AuthService
  ) { }

  ngOnInit(): void {

    // let personalDetails = this.authService.getSessionDetails('PersonalDetails');
    // if(this.authService.isUserLoggedIn() && personalDetails != null) {

    //   console.log(personalDetails)

    //   this.username = personalDetails['first_name']
    // } else {
    //   this.router.navigate(['/sessions/login'])

    // }

    if(!this.authService.isUserLoggedIn()) {
      this.router.navigate(['/sessions/login'])
    }


    this.modelForm = this.formBuilder.group({

      // email: ['', Validators.required], 
      // first_name: ['', Validators.required], 
      // last_name: ['', Validators.required], 
      // phone: ['', Validators.required], 

      address_1: ['', Validators.required], 
      address_2: ['', Validators.required], 
      city: [''], 
      state: ['' ], 
      postcode: ['', Validators.required], 
      building: ['', Validators.required],
      isshipping: [true],
      lat: ['', Validators.required],
      lng: ['', Validators.required],
      fullAddress: ['', Validators.required],
      ObjectString: ['', Validators.required],
      delivery_post_code: ['', Validators.required],
      delivery_city: ['', Validators.required]

    });
  }

  //mapbox
search(event, i) {

  if(typeof event.target !== undefined) {
    this.query = event.target['value'];
    this.showResults = true;
    this.loading = true;
    this.selectAddress = false;
  
    if (this.query.length > 2) {
      var formData = new FormData();
      formData.append('search_term', this.query)
      formData.append('limit', '10')
      formData.append('country', 'za')
  
      this._data.getAddress(formData).subscribe(
        data => {
        this.addresses[i] = data.data['addresses']
        //console.log('addresses are is: '+JSON.stringify(this.addresses))
        // this.scrollToBottom();
        if(this.deliveryFullAddress != "") {
          this.selectAddress = true;
  
        }
  
        },
        err => {
          console.log('error')
        },
        () => {}
      );
      this.loading = false;
    }
  }

}

addressSelect (fullAddress, lat, lng, address, i) {

  // alert('You have selected the following address: '+fullAddress)
  this.showResults = false;
  // this.doneSearch = true;
  // this.searchForm.get('searchable').disable();
  this.selectedAddress[i] = fullAddress;
  this.modelForm.get('lat').setValue(lat);
  this.modelForm.get('lng').setValue(lng);
  this.modelForm.get('fullAddress').setValue(fullAddress);
  this.modelForm.get('ObjectString').setValue(JSON.stringify(address));
  this.modelForm.get('delivery_post_code').setValue(address.postCode);
  this.modelForm.get('delivery_city').setValue(address.town);
  this.deliveryFullAddress =  fullAddress;
  this.selectAddress = true;

  
}

searchResetOnBackspace (event, i) {
  if (event.keyCode === 8 && this.selectedAddress[i]) { // if you hit backspace and have already selected a result, reset
      console.log('cleansed!');
      this.selectedAddress[i] = event.target['value'];
      this.search(event, i); // repopulate results
  }
};

getAddress(){
  //return an address 
  return this.deliveryFullAddress;

}

updateDetails() {
  if( this.modelForm.controls['lat'].value != "" && this.modelForm.controls['lng'].value  != "" && this.deliveryFullAddress != "" && this.modelForm.controls['delivery_city'].value != "") {
    //lets save up the recipient details 
    let DeliveryDropOffAddress = {
      lat :  this.modelForm.controls['lat'].value,
      lng: this.modelForm.controls['lng'].value ,
      fullAddress: this.deliveryFullAddress,
      delivery_city: this.modelForm.controls['delivery_city'].value ,
      delivery_post_code: this.modelForm.controls['delivery_post_code'].value,
      ObjectString: this.modelForm.controls['ObjectString'].value,
    }

    this.authService.saveSessionDetails(DeliveryDropOffAddress, "DeliveryDropOffAddress")
    // this.nav.navigate(['/home/payment-details-new-delivery'])
    this.router.navigate(['/order-now'])

  }
  
  return false;
}


}
