import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../_services/auth.service';
import { CommonDataService } from '../../../_services/common-data.service';
import { first } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';

@Component({
  selector: "app-registerpage",
  templateUrl: "registerpage.component.html"
})
export class RegisterpageComponent implements OnInit, OnDestroy {
  isCollapsed = true;
  focus;
  focus1;
  focus2;

  modelForm: FormGroup;
  buttonText = "Get Started";
  loading = false;

  apiUrl;

  fnWarning = false;
  lnWarning = false;
  emailWarning = false;
  phoneWarning = false;
  passWarning = false;
  passconfirmWarning = false;

  constructor(
    private authService: AuthService,  
    private router: Router, 
    private httpClient: HttpClient,  
    private formBuilder: FormBuilder,
    private _data: CommonDataService,
  ) {
    this.authService.logout();
  }

  @HostListener("document:mousemove", ["$event"])
  onMouseMove(e) {
    var squares1 = document.getElementById("square1");
    var squares2 = document.getElementById("square2");
    var squares3 = document.getElementById("square3");
    var squares4 = document.getElementById("square4");
    var squares5 = document.getElementById("square5");
    var squares6 = document.getElementById("square6");
    var squares7 = document.getElementById("square7");
    var squares8 = document.getElementById("square8");

    var posX = e.clientX - window.innerWidth / 2;
    var posY = e.clientY - window.innerWidth / 6;

    squares1.style.transform =
      "perspective(500px) rotateY(" +
      posX * 0.05 +
      "deg) rotateX(" +
      posY * -0.05 +
      "deg)";
    squares2.style.transform =
      "perspective(500px) rotateY(" +
      posX * 0.05 +
      "deg) rotateX(" +
      posY * -0.05 +
      "deg)";
    squares3.style.transform =
      "perspective(500px) rotateY(" +
      posX * 0.05 +
      "deg) rotateX(" +
      posY * -0.05 +
      "deg)";
    squares4.style.transform =
      "perspective(500px) rotateY(" +
      posX * 0.05 +
      "deg) rotateX(" +
      posY * -0.05 +
      "deg)";
    squares5.style.transform =
      "perspective(500px) rotateY(" +
      posX * 0.05 +
      "deg) rotateX(" +
      posY * -0.05 +
      "deg)";
    squares6.style.transform =
      "perspective(500px) rotateY(" +
      posX * 0.05 +
      "deg) rotateX(" +
      posY * -0.05 +
      "deg)";
    squares7.style.transform =
      "perspective(500px) rotateY(" +
      posX * 0.02 +
      "deg) rotateX(" +
      posY * -0.02 +
      "deg)";
    squares8.style.transform =
      "perspective(500px) rotateY(" +
      posX * 0.02 +
      "deg) rotateX(" +
      posY * -0.02 +
      "deg)";
  }

  ngOnInit() {
    this.prepForm()
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("register-page");

    this.onMouseMove(event);
  }
  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("register-page");
  }

  prepForm() {
    this.modelForm = this.formBuilder.group({
      name: ['', Validators.required], 
      email: ['', Validators.required], 
      phone: ['', Validators.required], 
      lname: ['', Validators.required], 
      pass: ['', Validators.required], 
      passconfirm: ['', Validators.required], 
    });
  }

  register() {

  	let first_name =  this.modelForm.controls['name'].value
  	let last_name = this.modelForm.controls['lname'].value
  	let email = this.modelForm.controls['email'].value
  	let phone = this.modelForm.controls['phone'].value
  	let password = this.modelForm.controls['pass'].value
  	let confirm_password = this.modelForm.controls['passconfirm'].value

  	if(this.validateFields()) {
      if(password == confirm_password ) {

        this.buttonText ='Please Wait...';

      //  let modelObject1 =  {
      //     "auth_password": password,
      //     "auth_username": email,
      //     "first_name": first_name,
      //     "last_name": last_name,
      //     "phone": phone,
      //     "source_id" : "6736FDPBVTGACFARVVVSFARTW6353GDAC",
      //     "email": email

      // }

      let modelObject1 = new FormData();
      modelObject1.append('auth_username', this.modelForm.controls['email'].value);
      modelObject1.append('auth_password', this.modelForm.controls['pass'].value);
      modelObject1.append('first_name', this.modelForm.controls['name'].value);
      modelObject1.append('last_name', this.modelForm.controls['lname'].value);
      modelObject1.append('phone', this.modelForm.controls['phone'].value);
      modelObject1.append('email', this.modelForm.controls['email'].value);
      modelObject1.append('source_id', '6736FDPBVTGACFARVVVSFARTW6353GDAC');


        this._data.dynamicFormPost(modelObject1, 'api-register-user') 
          .pipe(first())
          .subscribe(
              data => {
                  console.log("POST Request is successful ", data);
                  this.router.navigate(['/sellers/register/business-details/0']);

              },
              error => {
                  console.log("Error", error);
  
                  this.buttonText ='Register '

              },
  
              () => {
                  //continue where you left off
                  this.buttonText ='Register '

              }
          ); 

          return true;
  
      }
      
      else  {
        alert("Please ensure all fields are completed")
        this.passconfirmWarning = true;
        return false;
      }
    }
  
  }

  validateFields() {

    this.fnWarning = false;
    this.lnWarning = false;
    this.emailWarning = false;
    this.phoneWarning = false;
    this.passWarning = false;
    this.passconfirmWarning = false;

    let first_name = this.modelForm.controls['name'].value
    let fnWarning = this._data.validName.test(first_name)
    if(!fnWarning) {
      alert('wrong name format')
      this.fnWarning = true;
    }
      
    let last_name = this.modelForm.controls['lname'].value
    let lnWarning = this._data.validLastName.test(last_name)
    if(!lnWarning) {
      alert('wrong last name format')
      this.lnWarning = true;
    } 

    let email = this.modelForm.controls['email'].value
    let emailWarning = this._data.validEmail.test(email)
    if(!emailWarning) {
      alert('wrong emailWarning format')
      this.emailWarning = true;
    }

    let phone = this.modelForm.controls['phone'].value
    let phoneWarning = this._data.validSAPhone.test(phone)
    if(!phoneWarning) {
      alert('wrong phone number format')
      this.phoneWarning = true;

    }

    let password = this.modelForm.controls['pass'].value
    let passWarning = this._data.validPassword.test(password)
    if(!passWarning) {
      alert('password not strong enough')
      this.passWarning = true;

    }

    
    if(this.lnWarning || this.emailWarning || this.phoneWarning || this.passWarning )
      return false;

    else
     return true;

  }
}
