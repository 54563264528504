import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../_services/auth.service';
import { CommonDataService } from '../../../_services/common-data.service';
import { first } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';

@Component({
  selector: "app-profilepage",
  templateUrl: "profilepage.component.html"
})
export class ProfilepageComponent implements OnInit, OnDestroy {
  isCollapsed = true;

  modelForm: FormGroup;
  buttonText = ''

  constructor(
    private authService: AuthService,  
    private router: Router, 
    private httpClient: HttpClient,  
    private formBuilder: FormBuilder,
    private _data: CommonDataService,
  ) {
  }

  ngOnInit() {
    this.modelForm = this.formBuilder.group({
      name: ['', Validators.required], 
      email: ['', Validators.required], 
      phone: ['', Validators.required], 
      subject: ['', Validators.required], 
      message: ['', Validators.required], 
    });
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("profile-page");
    
  }
  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("profile-page");
  }

  prepForm() {
    this.modelForm = this.formBuilder.group({
      name: ['', Validators.required], 
      email: ['', Validators.required], 
      phone: ['', Validators.required], 
      subject: ['', Validators.required], 
      message: ['', Validators.required], 
    });
  }

  submit() {

    this.buttonText = 'Please Wait...';
    
    if (!this.modelForm.invalid) {
      this.sendEmailQuery(); // run combine function
      return true;

    }

    else {
      //error
      alert('Please complete all required fields before submitting')
      return false;

    }

  }

  sendEmailQuery() {

    let modelObject1 = new FormData();
    modelObject1.append('modelName', 'AdminEmail');

    modelObject1.append('emailDetails-fromName', this.modelForm.controls['name'].value);
    modelObject1.append('emailDetails-from', this.modelForm.controls['email'].value);
    modelObject1.append('emailDetails-subject', this.modelForm.controls['subject'].value);
    modelObject1.append('emailDetails-category', this.modelForm.controls['phone'].value);
    modelObject1.append('emailDetails-message', this.modelForm.controls['message'].value);

    this._data.dynamicFormPost(modelObject1, 'submit-query-message') 
    // this._data.updateModelData(modelObject1)
       .pipe(first())
       .subscribe(
         data => {
           console.log(data);

            // alert(data['quote']['methodId']['fee']);
         },
         error => {
         },

         () => {

            this.prepForm()
         }
     );
  }
}
