import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormsModule, FormArray  } from '@angular/forms';
import { first } from 'rxjs/operators';

//import { UserService } from '../../services/user.service';
import { CommonDataService } from '../../../_services/common-data.service';
import { AuthService } from '../../../_services/auth.service';

@Component({
  selector: 'app-profile-dashboard',
  templateUrl: './profile-dashboard.component.html',
  styleUrls: ['./profile-dashboard.component.scss']
})
export class ProfileDashboardComponent implements OnInit {
  orders = []
  personalDetails;
  registrationSettings = false
  loading = false

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private _data: CommonDataService,
    private authService:  AuthService
  ) {
    if(this.authService.getSessionDetails('PersonalDetails')) {
      this.personalDetails = this.authService.getSessionDetails('PersonalDetails');
      if(this.authService.getSessionDetails('registrationSettings')) {
        this.registrationSettings = this.authService.getSessionDetails('registrationSettings');

      }

    }
   }

  ngOnInit(): void {
    this.getUserOrders()
  }

  getUserOrders() {

    let context = sessionStorage.getItem('context')
    var formData = new FormData();
    formData.append('modelName', 'BackOrder')
    formData.append('context_id', context)


    this._data.getModelData(formData).subscribe(
      data => {
      this.orders = data['covers']
      console.log(data)
      //console.log('addresses are is: '+JSON.stringify(this.addresses))
      // this.scrollToBottom();
      },
      err => {
        console.log('error')
      },
      () => {}
    );
    this.loading = false;
  }

}
