import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../_services/auth.service';
import { Subject } from 'rxjs';

import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.scss']
})
export class HeaderNavComponent implements OnInit {
  isCollapsed = true;

  username = ""
  isLoggedIn = false
  personalDetails

  twitter = environment.twitter
  facebook = environment.facebook
  insta = environment.instagram

  subscription:any;


  constructor(
    private authService:  AuthService
  ) { 

    if(this.authService.getSessionDetails('PersonalDetails')) {
      this.personalDetails = this.authService.getSessionDetails('PersonalDetails');

      this.username = this.personalDetails['first_name']
      this.isLoggedIn = true
    } 

    //lets create a subscription of 
    this.subscription = this.authService.loggedInChanged.subscribe(
      (data) => {
        this.isLoggedIn = this.authService.isUserLoggedIn();
      }
    );
  }

  ngOnInit(): void {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("register-page");
  }

  navigation(url) {
    window.open(
      url,
      '_blank' // <- This is what makes it open in a new window.
    );  }

}
