import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormsModule, FormArray  } from '@angular/forms';
import { first } from 'rxjs/operators';

import { CommonDataService } from '../../../_services/common-data.service';
import { AuthService } from '../../../_services/auth.service';


@Component({
  selector: 'app-pay-now',
  templateUrl: './pay-now.component.html',
  styleUrls: ['./pay-now.component.scss']
})
export class PayNowComponent implements OnInit {

  gasBottles = []
  modelForm: FormGroup;

  orderItems = []
  productSelection = []
  order;
  loading = true
  paynowbtn = "Pay Now"
  orderId

  totalCost = 0.00
  totalCylinders = 0;

  tankSizes = []
  tankSize
  tanksizeOptions = []
  pageReady = false;
  deliveryAddress;
  username = ""
  personalDetails
  orderCreated

  orderData
  // supplierId
  totalCartCost
  totalInclusive
  serviceCharge
  quoteDetails
  supplierId
  waybillId
  waybillReference
  waybillQuoteAmount
  recipientDetails;

  constructor(
    private formBuilder: FormBuilder,
    private nav: Router,
    private _data: CommonDataService,
    private authService:  AuthService
  ) { }

  ngOnInit(): void {

    if(this.authService.getSessionDetails("DeliveryRecipientDetails")) {
      this.recipientDetails = this.authService.getSessionDetails("DeliveryRecipientDetails");

      if(this.authService.getSessionDetails("DeliveryDropOffAddress")) {
        this.deliveryAddress = this.authService.getSessionDetails("DeliveryDropOffAddress");

        if(this.authService.getSessionDetails("DeliveryLoadSize")) {
          this.orderItems = this.authService.getSessionDetails("DeliveryLoadSize");
          this.totalCartCost = this.authService.getSessionDetails("totalCartCost");
          this.completeOrder()
          // this.loading = false
          return;

        }
      }
    }


    // this.nav.navigate(['location-search'])

  }

  completeOrder() {

    let waybillRequestQuoteObject = {

      "toAddress": {
          "lat": this.deliveryAddress.lat,
          "lng": this.deliveryAddress.lng,
          "fullAddress": this.deliveryAddress.fullAddress,
          "post_code": this.deliveryAddress.delivery_post_code,
          "city": this.deliveryAddress.delivery_city,
          "baundary" : this.deliveryAddress.delivery_city,
          "phone": this.recipientDetails.contact_number,
          "email": this.recipientDetails.contact_email,
          "recipient" : this.recipientDetails.recipient_name
      },

      "orderItems": this.orderItems,
      "totalCartCost" : this.totalCartCost

    }


    this._data.dynamicFormPost(waybillRequestQuoteObject, 'locate-supplier-area-coverage') 
      .pipe(first())
      .subscribe(
        data => {

          if(data['orderItems'] != undefined && data['quote']['areaCovered'] == true) {

            this.pageReady = true;
            this.tankSizes = data['orderItems']
            this.order = data['order']

            if(data['quote'] != undefined && data['quote']['areaCovered'] == true) {
              this.supplierId = data['quote']['supplier']['id'];
              this.waybillId = data['quote']['waybillQuoteDetails']['model']['id'];
              this.waybillReference = data['quote']['waybillQuoteDetails']['model']['modelData']['systemManagedEntities']['parcelDetails']['quoteReference'];
              this.waybillQuoteAmount = data['quote']['shippingCost'];
  
              this.quoteDetails = {
                supplierId : data['quote']['supplier']['id'],
                turnAroundTime : data['quote']['supplier']['turnAroundTime'],
                waybillQuoteId : this.waybillId,
                waybillReference : this.waybillReference,
                waybillQuoteAmount : this.waybillQuoteAmount,
              }
  
              // this.totalCartCost = data['order']['totalCartCost'];
              // this.items = data['order']['totalProductsCost'];
              // this.itemsQuantity = data['order']['totalProductsCost'];
              this.totalInclusive = data['order']['totalInclusive'];
              this.serviceCharge = data['order']['serviceCharge'];
  
              this.authService.saveSessionDetails(this.quoteDetails, "CourierQuoteDetails")
              this.authService.saveSessionDetails(data['order'], "OrderDetails")
              this.createOrder (data['order']['id'])
              
            }
  
          }

          else {

            alert("Whoops something went wrong...Please try again. ")
            this.cancelOrder()
            this.nav.navigate(['/location-search'])


          }

          this.pageReady = true;
        },
        error => {

          alert("Whoops something went wrong...Please try again. ")
          this.cancelOrder()
          this.nav.navigate(['/location-search'])


        },
        () => {}
      );
  }

  cancelOrder() {
  
    this.authService.deleteSessionKey('DeliveryRecipientDetails')
    this.authService.deleteSessionKey('DeliveryDropOffAddress')
    this.authService.deleteSessionKey('DeliveryLoadSize')
    // this.authService.deleteSessionKey('CourierQuoteDetails')
  }


  createOrder (orderId) {
    // Initiate new model creation/update method
    let modelObject1 = new FormData();

    modelObject1.append('modelName', 'Order');
    modelObject1.append('id', orderId);
    modelObject1.append('billingInfo-email', this.recipientDetails.contact_email);
    modelObject1.append('billingInfo-first_name', this.recipientDetails.recipient_name);
    modelObject1.append('billingInfo-last_name', '');
    modelObject1.append('billingInfo-phone', this.recipientDetails.contact_number);

    modelObject1.append('billingInfo-address_1', '');
    modelObject1.append('billingInfo-address_2', '');
    modelObject1.append('billingInfo-fullAddress', this.deliveryAddress.fullAddress);
    modelObject1.append('billingInfo-city', this.deliveryAddress.delivery_city);
    modelObject1.append('billingInfo-state', this.deliveryAddress.delivery_city);
    modelObject1.append('billingInfo-postcode', this.deliveryAddress.delivery_post_code);
    modelObject1.append('billingInfo-lat', this.deliveryAddress.lat);
    modelObject1.append('billingInfo-lng', this.deliveryAddress.lng);


    modelObject1.append('billingInfo-building', this.deliveryAddress.delivery_notes);
    // modelObject1.append('isshipping', this.modelForm.controls['isshipping'].value);

    modelObject1.append('shippingAddress-lat', this.deliveryAddress.lat);
    modelObject1.append('shippingAddress-lng', this.deliveryAddress.lng);
    modelObject1.append('shippingAddress-fullAddress', this.deliveryAddress.fullAddress);
    modelObject1.append('shippingAddress-ObjectString', this.deliveryAddress.ObjectString);
    modelObject1.append('shippingAddress-delivery_post_code', this.deliveryAddress.delivery_post_code);
    modelObject1.append('shippingAddress-delivery_city', this.deliveryAddress.delivery_city);
    modelObject1.append('shippingAddress-collection_baundary_name', this.deliveryAddress.delivery_city);

    this._data.updateModelData(modelObject1) 
      .pipe(first())
      .subscribe(
        data => {
 
          this.orderId = data['result']['id'];
          this.orderData = data['result'];
          //lets create a session variable to track this order
          this.authService.saveSessionDetails( orderId, 'order')
          this.loading = false

          this.orderCreated = true;
        },
        error => {
          console.log(error);
        },

        () => {
          
        }
      );
  }

  payNow() {
    this.paynowbtn = "Please wait..."

    if(this.orderCreated) {
      // alert("order created")
      this.nav.navigate(["/complete-payment"]);
    } 

    else{
      // alert("order not created")

      this.nav.navigate(["/location-search"]);
    }
  }

}
