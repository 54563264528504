import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { switchMap, map, first } from 'rxjs/operators';
// import { Subject } from 'rxjs/Subject';
import { Subject } from 'rxjs';


const httpOptions = {
  headers: new HttpHeaders({
    //'Content-Type':  'application/json',
    //'Accept': 'application/json',
  }),
  withCredentials: false
};

@Injectable({
  providedIn: 'root'
})
export class CommonDataService {

  private modelData = new BehaviorSubject<any>(null); // store for quote data
  public modelData$ = this.modelData.asObservable();

  //set session data...

  //current order data
  currentOrder:any;
  subscription:any;
  queryParams = {mid:0};

  //query params subscription
  queryParamsChanged = new Subject<any>();
  
  constructor(private http: HttpClient) { 

    //lets create a subscription of 
    this.subscription = this.queryParamsChanged.subscribe(
      (data) => {
        this.queryParams = data;
      }
    );
  }

  // General Data
  
  // General Data Validation
  validName = new RegExp('^[a-zA-Z][a-zA-Z0-9 ]+$');
  validLastName = new RegExp('^[a-zA-Z][a-zA-Z0-9 ]+$');
  validEmail = new RegExp('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$');
  validSAPhone = new RegExp('^0(6|7|8){1}[0-9]{1}[0-9]{7}$');
  //validPassword = new RegExp('^(?=.*\d.*\d)(?=.*[^A-Za-z\d]).{6,}'); // length of password :minimum 6 letters, 2 digits, 1 Special sign
  validPassword = new RegExp('^.{6,}$')
  validWebUrl = new RegExp(/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/)

  //APi Data endpoints...
  dynamicFormPost(data, endpoint) {
    return this.http.post<any>(`${environment.apiUrl}/`+endpoint, data, httpOptions)
  }
  
  getModelData (data) {
    //console.log(data.get('modelName'));
    if(data.get('modelName')) {

      if(data.get('modelName') == 'botUser' || data.get('modelName') == 'User')
        return this.http.post<any>(`${environment.apiUrl}/query-users-data`, data, httpOptions)

      else
        return this.http.post<any>(`${environment.apiUrl}/query-model-data`, data, httpOptions)
    }
    else
      return this.http.post<any>(`${environment.apiUrl}/query-model-data`, data, httpOptions)
  } // Get model data

  getUsersData (data) {
    return this.http.post<any>(`${environment.apiUrl}/query-users-data`, data, httpOptions)
  } // Get model data

  updateModelData (data) {
    return this.http.post<any>(`${environment.apiUrl}/update-model-data`, data, httpOptions)
  } // Get existing customer loan

  getDefinationObject (data) {
    return this.http.post<any>(`${environment.apiUrl}/get-definition-object`, data, httpOptions)
  } // get the object nodel 

  createModelForm (data) {
    return this.http.post<any>(`${environment.apiUrl}/create-model-form`, data, httpOptions)
  } // get the object nodel 

  searchData (data) {
    return this.http.post<any>(`${environment.apiUrl}/search-model-data`, data, httpOptions)
  } // Get existing customer loan

  //user functions
  updateProfile (data) {
    return this.http.post<any>(`${environment.apiUrl}/update-registration-data`, data, httpOptions)
  } // Update the profile of the current user...

  getProfile () {
    return this.http.get<any>(`${environment.apiUrl}/get-user-info`,  httpOptions)
  } // Update the profile of the current user...
  
  getProfileSync () {
    return this.http.get<any>(`${environment.apiUrl}/get-user-info`,  httpOptions).toPromise()
  } // Update the profile of the current user...
  // Get Requests - Common Requests
  getRequest () {
    return this.http.get<any>(`${environment.apiUrl}/not-yet-implemented`, httpOptions)
  }

  getAddress (data) {
    return this.http.post<any>(`${environment.apiUrl}/location-search`, data, httpOptions)
  }

  removeFromModel(data) {
    return this.http.get<any>(`${environment.apiUrl}/not-yet-implemented`, httpOptions)
  }
  
  
}
