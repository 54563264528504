import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../_services/auth.service';
import { CommonDataService } from '../../../_services/common-data.service';
import { first } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';

@Component({
  selector: 'app-seller-business-details',
  templateUrl: './seller-business-details.component.html',
  styleUrls: ['./seller-business-details.component.scss']
})
export class SellerBusinessDetailsComponent implements OnInit {

  modelForm: FormGroup;
  buttonText = 'Submit Details'
  modelId = '0'
  modelData 

  constructor(
    private authService: AuthService,  
    private router: Router, 
    private route: ActivatedRoute,
    private httpClient: HttpClient,  
    private formBuilder: FormBuilder,
    private _data: CommonDataService,
  ) {
  }

  ngOnInit(): void {

    var body = document.getElementsByTagName("body")[0];
    body.classList.add("profile-page");

    this.modelForm = this.formBuilder.group({

      bussiness_email: ['', [Validators.required, Validators.email]], 
      bussiness_name: ['', Validators.required], 
      bussiness_phone: ['', Validators.required], 
      bussiness_website: ['', Validators.required], 
      bussiness_address_1: ['', Validators.required], 
      bussiness_address_2: ['' ], 
      bussiness_city: [''], 
      bussiness_state: ['' ], 
      bussiness_postcode: [''], 
      bussiness_building: ['' ],

    });

    this.route.params.subscribe((params) => {

      if(params['id']) {
        
        if(this.modelId != params['id']) {
          this.modelId = params['id'];
          //this.router.navigate(['/dashboard/model-list/'+this.modelName, this.modelId]) .then(() => window.location.reload());
        }
      }

      // this.getProfileAndUpdateProfile()
      
    });
  }

  getProfileAndUpdateProfile() {

    if (this.modelForm.invalid) {
      return false;
    }

    this._data.getProfile() 
    .pipe(first())
    .subscribe(
      data => {
        console.log(data);
        this.modelData = data['cover']['model']['modelData']['formFieldsEntities'];
        this.modelId = data['cover']['id'];
        this.createModel();

  
      },
      error => {
  
      },
  
      () => {
      }
    );

    return true;
  }

  createModel() {

    let modelObject1 = new FormData();

    modelObject1.append('modelName', 'User');
    //modelObject1.append('objectName', 'businessDetails');
    modelObject1.append('id', this.modelId);
    modelObject1.append('BusinessDetails-bussiness_email', this.modelForm.controls['bussiness_email'].value);
    modelObject1.append('BusinessDetails-bussiness_name', this.modelForm.controls['bussiness_name'].value);
    modelObject1.append('BusinessDetails-bussiness_phone', this.modelForm.controls['bussiness_phone'].value);
    modelObject1.append('BusinessDetails-bussiness_website', this.modelForm.controls['bussiness_website'].value);

    modelObject1.append('BusinessDetails-bussiness_address_1', this.modelForm.controls['bussiness_address_1'].value);
    modelObject1.append('BusinessDetails-bussiness_address_2', this.modelForm.controls['bussiness_address_2'].value);
    modelObject1.append('BusinessDetails-bussiness_city', this.modelForm.controls['bussiness_city'].value);
    modelObject1.append('BusinessDetails-bussiness_state', this.modelForm.controls['bussiness_state'].value);
    modelObject1.append('BusinessDetails-bussiness_postcode', this.modelForm.controls['bussiness_postcode'].value);

    modelObject1.append('BusinessDetails-bussiness_building', this.modelForm.controls['bussiness_building'].value);


    this.updateModel(modelObject1);

  }

  updateModel (modelObject: any) {
    // Initiate new model creation/update method

    this._data.updateProfile(modelObject) 
      .pipe(first())
      .subscribe(
        data => {
 
          // this.modelId = data['result']['id'];
          this.modelData =  data['cover']['model']['modelData']['formFieldsEntities'];

        },
        error => {
          console.log(error);

          this.buttonText = 'Submit Details ';

        },

        () => {
 
          this.buttonText = 'Submit Details ';

          this.createSeller()

        }
      );
  }

  createSeller() {

    this.modelForm = this.formBuilder.group({
      seller: [true]
    });

    let modelObject1 = new FormData();

    modelObject1.append('modelName', 'User');
    modelObject1.append('is_seller', this.modelForm.controls['seller'].value);

    this._data.dynamicFormPost(modelObject1, 'api-create-seller') 
      .pipe(first())
      .subscribe(
        data => {

          if(data['sellerProfile'] != undefined) {
            sessionStorage.setItem('BNFGSHGFGG', 'TYHH50545HSJSJ21FFSDGSFFFSBHSFVSTVS410045');
            // this.authService.authDetailsChanged.next([]);

            this.modelForm = this.formBuilder.group({

              bussiness_email: ['', [Validators.required, Validators.email]], 
              bussiness_name: ['', Validators.required], 
              bussiness_phone: ['', Validators.required], 
              bussiness_website: ['', Validators.required], 
              bussiness_address_1: ['', Validators.required], 
              bussiness_address_2: ['' ], 
              bussiness_city: [''], 
              bussiness_state: ['' ], 
              bussiness_postcode: [''], 
              bussiness_building: ['' ],
        
            });
          }

          // this.modelData = data['cover']['model']['modelData']['formFieldsEntities'];

          // if(data['cover']['model']['modelData']['formFieldsEntities']['is_seller']) {
          //   //localStorage.setItem('BNFGSHGFGG', 'TYHH50545HSJSJ21FFSDGSFFFSBHSFVSTVS410045');
          //   sessionStorage['BNFGSHGFGG'] = 'TYHH50545HSJSJ21FFSDGSFFFSBHSFVSTVS410045';
          // }
        },
        error => {
          console.log(error);
          alert('Whoops something went wrong. Please try again!')
        },

        () => {

        }
      );
  }

}
